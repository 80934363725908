import { environment } from "src/environments/environment";

const designerProductControllerRoot = 'designer-product/';

export const ApiUrls = {

  ipAddress: environment.usersApiRoot + 'common/ipaddress',
  designerDetails :  environment.usersApiRoot + 'designer/details',

  // Product related
  getAllFabricType: environment.ordersApiRoot + designerProductControllerRoot + 'fabric-type',
  getAllfabricPatterns: environment.ordersApiRoot + designerProductControllerRoot + 'fabric-pattern',
  getAllFabricColors: environment.ordersApiRoot + designerProductControllerRoot + 'fabric-color',
  getAllDressCatogories: environment.ordersApiRoot + designerProductControllerRoot + 'dress-category',
  manageDesignerProduct: environment.ordersApiRoot + designerProductControllerRoot,
  getOneProduct: environment.ordersApiRoot + "product/",
  getDesignerProduct:(productId : string) => environment.ordersApiRoot + `designer-product/${productId}`,

  // Request related
  getAllQuotesApi: (status: number) => environment.ordersApiRoot + `designer-quote/${status}`,
  getOneQuoteDetailsApi: (quoteId: number) => environment.ordersApiRoot + `designer-quote/quote/${quoteId}`,
  acceptQuoteApi: (quoteId: number) => environment.ordersApiRoot + `designer-quote/quote/${quoteId}/accept`,
  rejectQuoteApi: (quoteId: number) => environment.ordersApiRoot + `designer-quote/quote/${quoteId}/reject`,

  // User related
  loginApi: environment.identityApiRoot + 'authorize/designer/login',
  refreshToken: environment.identityApiRoot + 'authorize/designer/refreshToken',
  resetPassword: (userName: string) => environment.identityApiRoot + `authorize/designer/${userName}/forgotpassword/request`,
  resetPasswordOTPValidate: (userName: string, otp: string) => environment.identityApiRoot + `authorize/designer/${userName}/forgotpassword/${otp}/validate`,
  completeResetPassword: environment.identityApiRoot + `authorize/designer/forgotpassword`,
  registerDesigner: environment.usersApiRoot + 'designer/register',
  getDesignerPolicyContent: environment.usersApiRoot + 'designer/policy',
  verifyEmail: (email : string) => environment.identityApiRoot + `authorize/designer/${email}/send-otp`,
  submitDesignerRegistrationOTP: (userId: string, otp: string) => environment.identityApiRoot + `authorize/designer/${userId}/confirm/${otp}`,
  phoneNumberExistsCheck: (phoneNumber: string) => environment.identityApiRoot + `authorize/designer/${phoneNumber}/exists`,
  emailExistsCheck: (email: string) => environment.identityApiRoot + `authorize/designer-email/${email}/exists`,
  brandNameExistsCheck: (brandName: string) => environment.usersApiRoot + `designer/brandname/${brandName}/exists`,
  updateDesignerLogo: environment.ordersApiRoot + `designer-order/uploadimage`,
  updateDesignerPhone: (phone: string) => environment.usersApiRoot + `designer/update-phone/${phone}`,
  downloadDesignerConcent: environment.usersApiRoot + `designer/policy/download`,

  designerAddress :(designerAddressId : number) => environment.usersApiRoot + `designer/update-address/${designerAddressId}`,

  getPincodeDetails : (pincode: string) => environment.usersApiRoot + `common/zipcode/${pincode}`,
  pincodeValidCheck : (pincode: string) => environment.usersApiRoot + `designer/${pincode}/valid`,

  requestPickUp :(quoteId : number) => environment.ordersApiRoot + `designer-quote/quote/${quoteId}/requestpickup`,
  resetDesgnerPassword: environment.identityApiRoot + `authorize/designer/resetpassword`,
}
